<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I find air legislation?</h2>
      <p>
        Air Legislation is an important and detailed subject. As a student learning to
        fly, you are encouraged to learn as much as you can about the law around aviation.
        Your instructor will guide you through your training on the air legislation which
        is relevant to you, however, the more you learn, the more you can discuss it with
        your instructor.
      </p>
      <p>
        There are several documents that make up a complete picture of Air Legislation.
        The following documents can be found on
        <a href="https://legislation.gov.au">legislation.gov.au</a> by simply typing their
        name into the Search function.
      </p>
      <div class="list-container">
        <ul class="steps-list">
          <li>Civil Aviation Safety Regulations 1998 <i>(abbreviated: CASR1998)</i></li>
          <li>Civil Aviation Regulations 1988 <i>(abbreviated: CAR1988)</i></li>
          <li>Civil Aviation Order &hellip; <i>(abbreviated: CAO &hellip;)</i></li>
          <li>Manuals of Standards &hellip; <i>(abbreviated: MOS)</i></li>
        </ul>
      </div>
      <p>
        The CASR1998 is divided into <i>parts</i>. For example, your instructor may have
        referred you to <quote>"Part 61 of the CASR1998"</quote>. This means that under the CASR1998, you
        will find a number of regulations under Part 61, which is about flight crew licencing.
        Each regulation, under each part, has a number associated with it. For example,
        the CASR1998 Part 61 regulation 345 is about maintaining a personal pilot logbook.
      </p>
      <p>
        The CAR1988 is divided into <i>two volumes</i>. Similar to the CASR1998, Volume 1
        contains a number of <i>parts</i>, each of which has several regulations with a number
        associated with it. For example, CAR1988, Part 11, regulation 157 is about low flying.
        Regulations in the CAR1988 are also grouped into <i>divisions</i> and <i>sub-divisions</i>
        so that you may easily refer to a group of regulations. Volume 2 of the CAR1988 contains
        <i>schedules</i>. These are structured slightly differently to those in Volume 1.
      </p>
      <p>
        There are many different Civil Aviation
        Order documents and each is followed by a number in its name. For example, Civil Aviation
        Order 20.18 is about the basic operational requirements of Aircraft equipment,
        while Civil Aviation Order 20.16.3 is about the requirements for carriage of
        persons such as seat belts and the carrying of infants.
      </p>
      <p>
        There are many different Manuals of Standards and each is associated with a <i>part</i>
        number. A student is likely to be particularly interested in the <b>Part 61 Manual of Standards</b>,
        which contains many of the competency requirements for Flight Crew Licencing.
      </p>
      <p>
        Another legislative document is the AirServices Aeronautical Information Package (AIP).
        You will find a link to the AIP under
        <router-link to="/student-resources">Student Resources</router-link>.
        This document package is made up of a number of sub-documents, each containing
        detailed information about aviation operations:
      </p>
      <div class="list-container">
        <ul class="steps-list">
          <li>AIP Book</li>
          <li>AIP Charts</li>
          <li>Summary of SUP/AIC</li>
          <li>Departure and Approach Procedures (DAP)</li>
          <li>Designated Airspace Handbook (DAH)</li>
          <li>En Route Supplement Australia (ERSA)</li>
          <li>Precision Approach Terrain Charts and Type A &amp; Type B Obstacle Charts</li>
          <li>World Aeronautical Charts (WAC)</li>
        </ul>
      </div>
      <p>
        The key AIP sub-documents that you will find helpful as a student are the AIP Book, AIP Charts
        and the ERSA. The AIP Book is itself, further divided into sub-documents and each of those is helpful
        to a student.
      </p>
      <p>
        There is another category of documents called Civil Aviation Advisory Procedures (CAAP).
        Each of these has a number associated with it. For example, CAAP 234-1 contains guidelines
        for aircraft fuel requirements. Although CAAP documents are not regulatory, they provide guidance
        and explanatory information about the meaning of certain requirements in the CAR1988.
        They may also describe methods to help you comply with a regulatory requirement under CAR1988 in a manner that
        would be acceptable to an authorised person or CASA. CAAPs are advisory and should always
        be read in conjunction with the referenced CAR1988 regulation(s).
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';

.list-container {
  display: inline-block;
}

.steps-list {
  list-style-type: square;
  text-align: left;
}
</style>
